<template>
  <div>
    <div class="BGS">
      <div class="crumbs"><a href="/PersonalCenter/MyComplaint">个人中心</a> > 立即评价</div>
      <div class="title">对商家处理效率是否满意？</div>
      <van-rate v-model="value" :size="25" color="#FE9025" void-icon="star" void-color="#eee"/>
      <span class="num" v-if="value == 1">非常差</span>
      <span class="num" v-if="value == 2">差</span>
      <span class="num" v-if="value == 3">一般</span>
      <span class="num" v-if="value == 4">好</span>
      <span class="num" v-if="value == 5">非常好</span>
      <div class="title">对商家服务结果是否满意？</div>
      <van-rate v-model="value1" :size="25" color="#FE9025" void-icon="star" void-color="#eee"/>
      <span class="num" v-if="value1 == 1">非常差</span>
      <span class="num" v-if="value1 == 2">差</span>
      <span class="num" v-if="value1 == 3">一般</span>
      <span class="num" v-if="value1 == 4">好</span>
      <span class="num" v-if="value1 == 5">非常好</span>
    </div>
    <div class="Comments_stars1">
      <textarea name="" id="" cols="40" v-model="textVal" maxlength="500" rows="10" placeholder="请留下您的宝贵意见"></textarea>
      <img src="@/assets/img/bi_icon.png" alt="">
    </div>
    <div class="Submit" @click="Submit">提交</div>
  </div>

</template>

<script>
import {userComplaint_evaluate} from "../../api/api"
export default {
   metaInfo(){
     return{
        title: '评价',
        meta: [
            {
                name: 'keywords',
                content: '鹰眼投诉_消费投诉维权平台,集体投诉,商家投诉,权威帮帮团,最新投诉报道,打假维权,质量曝光,客服咨询'
            },
            {
                name: 'description',
                content:'鹰眼投诉_消费投诉维权平台是驱动中国旗下的投诉平台,快速解决消费投诉,投诉维权,共享服务投诉,购物平台投诉,旅游出行投诉,住宿投诉,娱乐生活投诉,教育培训投诉,金融支付投诉等,拥有海量企业库,各领域专家,专业律师团队及权威帮帮团来帮助消费者。'
            }
        ]
     }     
    },
  data() {
    return {
      value: 0,
      value1: 0,
      textVal:'',
      id:'',
      objid:'',
    };
  },
  methods: {
    Submit(){
      //console.log(this.value);
      //console.log(this.value1);
      //console.log(this.textVal);
      //console.log(this.id);
      //console.log(this.objid);
      if(this.value ==''){
                 this.$message.error({message: "请选择评价",});
      }else if(this.value1 ==''){
        this.$message.error({message: "请选择评价",});
      }else if(this.textVal == ''){
        this.$message.error({message: "意见不能为空",});
      }else{
          userComplaint_evaluate({complaint_business_id:this.objid,complaint_id:this.id,efficiency_results_level:this.value,service_level:this.value1,opinion_info:this.textVal}).then((res) => {
            //console.log(res);
            this.$message.success({message: res.data.msg});
          setTimeout("location.href='/PersonalCenter/MyComplaint'",1000);
            // this.$message({
            //   type: "success",
            //   message: "发起成功",
            // });
          });
      }
    }
  },
  mounted(){
    //console.log(this.$route.query)
    this.id=this.$route.query.id;
    this.objid=this.$route.query.objid;
  }
};
</script>

<style scoped>
.BGS {
  background: #fff;
  margin-left: 20px;
  width: 940px;
  padding: 20px 0 10px 20px;
}
.crumbs {
  background: #fff;
}
.title {
  margin-top: 30px;
  font-size: 18px;
  font-weight: 800;
  color: #333333;
  margin-bottom: 10px;
}
.num {
  margin-left: 20px;
  vertical-align: super;
  font-size: 18px;
  font-weight: 800;
  color: #fe9025;
}

.Comments_stars1 {
  width: 960px;
  height: 214px;
  background: #ffffff;
  margin: 20px 0 0 20px;
  padding-top: 20px;
  position: relative;
}
.Comments_stars1 textarea {
  width: 880px;
  margin: 0 auto;
  padding: 0px 0 0 50px;
  resize: none;
  border: none;
}
.Comments_stars1 img {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 20px;
  left: 25px;
}
.Submit{
width: 160px;
height: 48px;
background: #1F72D9;
border-radius: 10px;
font-size: 18px;
font-weight: 500;
color: #FEFEFF;
text-align:center;
line-height:48px;
margin:40px auto;
cursor: pointer;
}
</style>